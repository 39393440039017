export enum ETypesComponentStrapi {
  class = 'pagina_turma.midia',
  classPUT = 'pagina_turma',
  registersPUT = 'registro',
  intro = 'introducao',
  registers = 'registros.midia',
  registersText = 'registros.texto',
  paginas_aulas = 'paginas.aulas',
  paginas_imagem = 'paginas.imagem',
  paginas_footer = 'paginas.footer',
  professor = 'professor',
  professor_midia = 'professor.foto',
  photo = 'foto',
  serie = 'serie',
  series = 'series',
  professor_book = 'livro_professors',
  paginas_registros = 'paginas_registros.registro.midia',
  categories = 'categorias',
  materiais = 'materiais'
}

export enum StrapiComponentType {
  pages = 'paginas',
  intro = 'introducao',
  lessons = 'aulas',
  class = 'pagina_turma',
  photo = 'foto',
  serie = 'serie',
  series = 'series',
  categories = 'categorias',
  materiais = 'materiais',
  professor = 'professor'
}
