import { Injectable } from '@angular/core';
import { ApiGatewayService } from '../api-gateway.service';
import { Observable, map } from 'rxjs';
import {
  AuthRefreshResponseModel,
  AuthResponseModel
} from '../../models/auth.model';
import { WrappedModel } from '../../models/liv-response-protocol.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private apiGatewayService: ApiGatewayService) {}

  public validateGuestKey(key: string): Observable<AuthResponseModel> {
    const headers = new HttpHeaders()
      .append('guest-key', key)
      .append('x-skip-interceptor', 'Content-Type');

    return this.apiGatewayService
      .get<WrappedModel<AuthResponseModel>>('/auth/local', {
        headers
      })
      .pipe(map(({ data }) => data.attributes));
  }

  public refreshAuthentication(
    refreshToken: string
  ): Observable<AuthRefreshResponseModel> {
    const headers = new HttpHeaders().set('refresh-token', refreshToken);

    return this.apiGatewayService
      .get<WrappedModel<AuthRefreshResponseModel>>('/auth/refresh', {
        headers
      })
      .pipe(map(({ data }) => data.attributes));
  }
}
