import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { SetCurrentIndexPageAction } from './page.action';

export interface PageStateModel {
  leftIndex: number | null;
  rightIndex: number | null;
}

const PAGES_TOKEN = new StateToken<PageStateModel>('pages');

@State<PageStateModel>({
  name: PAGES_TOKEN,
  defaults: {
    leftIndex: null,
    rightIndex: null
  }
})
@Injectable()
export class PageState {
  @Selector() static leftIndexPage(state: PageStateModel): number | null {
    return state?.leftIndex ?? null;
  }

  @Selector() static rightIndexPage(state: PageStateModel): number | null {
    return state?.rightIndex ?? null;
  }

  @Action(SetCurrentIndexPageAction)
  setCurrentIndexPage(
    { patchState }: StateContext<PageStateModel>,
    { pageIndex }: SetCurrentIndexPageAction
  ): void {
    patchState({
      rightIndex: pageIndex,
      leftIndex: pageIndex - 1 >= 0 ? pageIndex - 1 : null
    });
  }
}
