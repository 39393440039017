import { Injectable } from '@angular/core';
import {
  CreateHotToastRef,
  HotToastService,
  ToastOptions
} from '@ngneat/hot-toast';

@Injectable()
export class ToastService {
  readonly options: ToastOptions<unknown> = {
    duration: 5000
  };
  constructor(private hotToastService: HotToastService) {}

  success(
    message: string,
    override: ToastOptions<unknown> = {}
  ): CreateHotToastRef<unknown> {
    return this.hotToastService.success(message, {
      ...this.options,
      className: 'custom-toast-success',
      ...override
    });
  }

  error(
    message: string,
    override: ToastOptions<unknown> = {}
  ): CreateHotToastRef<unknown> {
    return this.hotToastService.error(message, {
      ...this.options,
      className: 'custom-toast-error',
      ...override
    });
  }

  info(
    message: string,
    override: ToastOptions<unknown> = {}
  ): CreateHotToastRef<unknown> {
    return this.hotToastService.info(message, {
      ...this.options,
      className: 'custom-toast-info',
      ...override
    });
  }

  warn(
    message: string,
    override: ToastOptions<unknown> = {}
  ): CreateHotToastRef<unknown> {
    return this.hotToastService.warning(message, {
      ...this.options,
      className: 'custom-toast-warning',
      ...override
    });
  }
}
