import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store
} from '@ngxs/store';
import { catchError, tap, throwError } from 'rxjs';

import {
  LoginAction,
  LogoutAction,
  RefreshTokenAction,
  RemoveAccessTokenAction,
  RemoveGuestKeyAction,
  SessionExpiredAction,
  SetProfessorAction
} from './session.actions';
import { ProfessorModel } from './session.model';
import { LivErrorResponse } from '../../models/liv-response-protocol.model';
import { AuthService } from '../../services/requests/auth.service';

export interface SessionStateModel {
  accessToken: string | null;
  refreshToken: string | null;
  guestKey: string | null;
  professor: ProfessorModel | null;
  identifier: string | null;
}

const SESSION_STATE_TOKEN = new StateToken<SessionStateModel>('session');

@State<SessionStateModel>({
  name: SESSION_STATE_TOKEN,
  defaults: {
    accessToken: null,
    refreshToken: null,
    guestKey: null,
    professor: null,
    identifier: null
  }
})
@Injectable()
export class SessionState {
  constructor(
    private store: Store,
    private authService: AuthService,
    private router: Router
  ) {}

  @Selector([SessionState]) static session({
    accessToken,
    refreshToken,
    guestKey
  }: SessionStateModel) {
    return { accessToken, refreshToken, guestKey };
  }

  @Selector([SessionState]) static accessToken({
    accessToken
  }: SessionStateModel) {
    return accessToken;
  }

  @Selector([SessionState]) static refreshToken({
    refreshToken
  }: SessionStateModel) {
    return refreshToken;
  }

  @Selector([SessionState]) static guestKey({ guestKey }: SessionStateModel) {
    return guestKey;
  }

  @Selector([SessionState]) static isAuthenticated({
    accessToken
  }: SessionStateModel) {
    return !!accessToken;
  }

  @Selector([SessionState]) static professor({
    professor
  }: SessionStateModel): SessionStateModel['professor'] {
    return professor;
  }

  @Selector([SessionState]) static identifier({
    identifier
  }: SessionStateModel) {
    return identifier;
  }

  @Action(RemoveAccessTokenAction) removeAccessToken({
    patchState
  }: StateContext<SessionStateModel>): void {
    patchState({
      accessToken: null
    });
  }

  @Action(RemoveGuestKeyAction) removeGuestKey({
    patchState
  }: StateContext<SessionStateModel>): void {
    patchState({
      guestKey: null
    });
  }

  @Action(SetProfessorAction) setProfessor(
    { patchState }: StateContext<SessionStateModel>,
    { payload }: SetProfessorAction
  ): void {
    patchState({
      professor: payload
    });
  }

  @Action(LoginAction) login(
    { patchState }: StateContext<SessionStateModel>,
    { payload }: LoginAction
  ) {
    if (!payload.guestKey) {
      throw new Error('Guest Key is required');
    }

    return this.authService.validateGuestKey(payload.guestKey).pipe(
      catchError(({ error }: LivErrorResponse) => {
        this.resetStore();
        return throwError(() => error?.message || 'Erro ao validar Guest Key');
      }),
      tap(
        ({
          id,
          id_externo_professor,
          apelido,
          email,
          jwt,
          refresh_token,
          uuid
        }) => {
          const storedId = this.store.selectSnapshot(SessionState.identifier);
          patchState({
            accessToken: jwt,
            refreshToken: refresh_token,
            guestKey: payload.guestKey,
            identifier: storedId || uuid,
            professor: {
              id,
              externalProfessorId: id_externo_professor,
              username: apelido,
              email
            }
          });
        }
      )
    );
  }

  @Action(RefreshTokenAction, {
    cancelUncompleted: true
  })
  refreshToken({ getState, patchState }: StateContext<SessionStateModel>) {
    const { refreshToken } = getState();

    if (!refreshToken) {
      this.store.dispatch(new SessionExpiredAction());
      return throwError(() => new Error('Refresh Token is required'));
    }

    return this.authService.refreshAuthentication(refreshToken).pipe(
      tap(({ jwt, refreshToken }) => {
        patchState({
          accessToken: jwt,
          refreshToken
        });
      })
    );
  }

  @Action(LogoutAction) logout({
    patchState
  }: StateContext<SessionStateModel>) {
    patchState({
      accessToken: null,
      professor: null
    });

    void this.router.navigate(['401'], {
      replaceUrl: true
    });
  }

  @Action(SessionExpiredAction)
  sessionExpired() {
    this.resetStore();
  }

  private resetStore() {
    this.store.reset({});
  }
}
