import {
  AccessTokenModel,
  GuestKeyModel,
  LoginModel,
  ProfessorModel
} from './session.model';

export class SetProfessorAction {
  static readonly type = '[Session] Set professor content';
  constructor(public payload: ProfessorModel) {}
}

export class SetAccessTokenAction {
  static readonly type = '[Session] Set access token';
  constructor(public payload: AccessTokenModel) {}
}

export class RemoveAccessTokenAction {
  static readonly type = '[Session] Remove access token';
  constructor() {}
}

export class SetGuestKeyAction {
  static readonly type = '[Session] Set guest key';
  constructor(public payload: GuestKeyModel) {}
}

export class RemoveGuestKeyAction {
  static readonly type = '[Session] Remove guest key';
  constructor() {}
}

export class LoginAction {
  static readonly type = '[Session] Login with guest key';
  constructor(public payload: LoginModel) {}
}

export class LogoutAction {
  static readonly type = '[Session] Logout';
  constructor() {}
}

export class SessionExpiredAction {
  static readonly type = '[Session] Expired';
}

export class RefreshTokenAction {
  static readonly type = '[Session] Refresh token';
  constructor() {}
}
