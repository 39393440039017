export enum EPages {
  cover = 1,
  authors = 2,
  intro = 3,
  categories = 4,
  lesson = 5,
  category_lesson = 6,
  all_lessons = 7,
  resources_materials = 8,
  like_next_year = 9,
  creative_space = 10,
  cover_back = 11,
  intro_creative_space = 12
}
