import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  fromEvent,
  map
} from 'rxjs';

@Injectable()
export class WindowResizeService implements OnDestroy {
  private resizeObservable$: Observable<Event> | undefined;
  private innerWidthState$: BehaviorSubject<number> =
    new BehaviorSubject<number>(window.innerWidth);
  private destroy$ = new Subject<boolean>();

  private readonly MOBILE_WIDTH_SIZE = 768;

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();

    this.innerWidthState$?.unsubscribe();
  }

  get isMobileWidthSize(): boolean {
    return window.innerWidth <= this.MOBILE_WIDTH_SIZE;
  }

  get isMobile(): boolean {
    const match = window.matchMedia;

    if (match) {
      const mq = match('(pointer:coarse)');

      return mq.matches;
    }

    if (typeof screen.orientation !== 'undefined') {
      return true;
    }

    return false;
  }

  onResize(debounce = 250): Observable<number> {
    this.resizeObservable$ = fromEvent(window, 'resize');

    return this.resizeObservable$.pipe(
      debounceTime(debounce),
      map(({ target }) => {
        const { innerWidth } = target as Window;

        return innerWidth;
      })
    );
  }
}
